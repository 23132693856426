import axios from 'axios';

const spribe = axios.create({
    baseURL: process.env.VUE_APP_BASE_SPRIBE_URL,
});

spribe.defaults.headers.post['Content-Type'] = 'application/json';
spribe.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

spribe.defaults.method = "post";

export default spribe;