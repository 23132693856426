<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <div class="title_name w-100 text-center" style="margin-top:0px">
          <h6 class="mb-0 pt-1"></h6>
        </div>
      </div>

      <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL"></iframe>
    </div>

  </div>
</template>
<script>

import shacksevo from "@/services/shacksevo";

export default {
  name: "cointoss",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: "cointoss",
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: false,
    };

  },
  mounted() {

    var p = this.getProfile();

    if(!p) {

      this.isDemo = true;

    }

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();
    this.launchURL = this.gameURL();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.$store.state.profile;
    },

    token: function () {
      return this.$store.state.profile.auth;
    },

  },
  methods: {

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    gameURL: function () {

      var vm = this;

      var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      var data = {
        game_id: this.gameID,
        account_id: parseInt(accountId),
        lobby_url: window.location.hostname
      }

      vm.loading = 'loading';
      vm.isLoaded = false;

      let headers = {
        headers: {
          'api-key': vm.getAuth()
        },
      }

      let path = "/launch/url"

      shacksevo.post(path, JSON.stringify(data),headers).then(res => {

        this.isLoaded = true;
        vm.launchURL = res.data.game_url;

      }).catch(err => {

        vm.launchGameError(err)

      })

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.title_name {
  color: #fff;
}
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.parent .banner {
  width: 100%;
  /* height: 48px; */
}
.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}
h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>