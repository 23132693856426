import axios from 'axios';

const smart = axios.create({
    baseURL: process.env.VUE_APP_SMARTSOFT_BASE_URL,
});

smart.defaults.headers.post['Content-Type'] = 'application/json';
smart.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

smart.defaults.method = "post";

export default smart;